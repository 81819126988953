/*
 * 업무구분: 안내자료
 * 화 면 명: MSPSA206P
 * 화면설명: 안내자료 카테고리(조회조건)
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.14
 * 작 성 자: 손평국
 */
 
<template>
  <ur-page-container title="분류별보기" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <!-- 간편정보 아코디언 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-category-list">
        <mo-list :list-data="lv_UntySlmtClsf">
          <template #list-item="{ item }">
            <mo-list-item expandable ref="expItem">
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <div class="txt_box">
                    <span class="theads">{{item.clsfSytmNm}}</span>
                  </div>
                </div>
              </div>
              <template v-slot:expand class="ns-gray-box">
                <div class="list-item__contents">
                  <div class="category-area2">
                    <template v-for="item2 in item.sAMClsfSytmCntntVO">
                        <div class="category-box" :key="item2.clsfSytmId">
                          <strong>{{item2.clsfSytmNm}}</strong> 
                          <template v-for="item3 in item2.tTSA00003DVO">
                            <a href="#" @click="fn_ItemClick(item,item2,item3)" :key="item3.envCntnt">{{item3.smoUnitl}}</a>
                          </template>
                        </div>
                    </template>
                    <!-- <div class="category-box">
                      <strong></strong> 
                    </div> -->
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- 간편정보 아코디언 //-->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA206P",
    screenId: "MSPSA206P",
    props: {
      paramData: []

    },
    components: {
      
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // title: '안내자료_고객선택 수신(SMS)', // 화면명
        isMngr: false, // 지점장 여부
        authCd: 'U000', // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        lv_UntySlmtClsf: [],
        
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     
    /** computed 함수 정의 영역 */
    computed: {
    
    },
    /** watch 정의 영역 */
    watch: {
      
    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      this.lv_UntySlmtClsf = this.getStore('spStore').state.clsfSytmCntntList
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /*********************************************************
      * Function명: fn_ItemClick
      * 설명: 소분류 클릭 이벤트 핸들러
      * Params: N/A
      * Return: N/A
      *********************************************************/
      fn_ItemClick (item, item2, item3) {
        let returnValue = {}

        returnValue.item1 = item
        returnValue.item2 = item2
        returnValue.item3 = item3

        this.$emit('popupCloseMSPSA206P', returnValue)
      }
    }
  };
</script>
<style scoped>
</style>